<template>
  <div class="login-container">
    <el-card class="login-card" :class="{ 'mobile': isMobile }">
      <div class="login-header">
        <div class="logo">
          <i class="el-icon-s-platform"></i>
        </div>
        <div class="title">管理后台</div>
      </div>
      
      <el-form 
        ref="loginForm" 
        :model="loginForm" 
        :rules="loginRules"
        label-width="0"
        size="large"
      >
        <el-form-item prop="username">
          <el-input 
            v-model="loginForm.username"
            placeholder="请输入用户名"
            clearable
          >
            <template slot="prefix">
              <i class="el-icon-user custom-icon"></i>
            </template>
          </el-input>
        </el-form-item>
        
        <el-form-item prop="password">
          <el-input 
            v-model="loginForm.password"
            type="password"
            placeholder="请输入密码"
            show-password
            @keyup.enter.native="handleLogin"
          >
            <template slot="prefix">
              <i class="el-icon-lock custom-icon"></i>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button 
            type="primary" 
            :loading="loading"
            class="login-button"
            @click="handleLogin"
            size="large"
          >登 录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      loading: false,
      isMobile: false
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async handleLogin() {
      try {
        const valid = await this.$refs.loginForm.validate();
        if (valid) {
          this.loading = true;
          const res = await this.$http.post('/api/admin/login', this.loginForm);
          
          if (res.data.code === 200) {
            const token = res.data.data.token;
            localStorage.setItem('admin_token', token);
            localStorage.setItem('admin_info', JSON.stringify(res.data.data.admin));
            
            this.$http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            this.$message({
              message: '登录成功',
              type: 'success',
              duration: 1500
            });
            
            this.$emit('login-success');
          }
        }
      } catch (error) {
        const response = error.response?.data;
        if (response?.message) {
          this.$message.error(response.message);
        } else {
          this.$message.error('登录失败，请重试');
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1f2d3d 0%, #304156 100%);
}

.login-card {
  width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.95);
}

.login-header {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  font-size: 48px;
  color: #409EFF;
  margin-bottom: 16px;
}

.title {
  font-size: 24px;
  color: #303133;
  font-weight: 500;
}

.login-button {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  margin-top: 10px;
}

/* 输入框样式优化 */
:deep(.el-input__inner) {
  height: 45px;
  line-height: 45px;
  padding: 0 15px 0 40px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  background-color: #fff;
  color: #606266;
  text-align: center;
  letter-spacing: 1px;
}

:deep(.el-input__inner::placeholder) {
  text-align: center;
}

:deep(.el-input__prefix) {
  left: 15px;
  height: 100%;
  display: flex;
  align-items: center;
}

.custom-icon {
  font-size: 16px;
  color: #909399;
}

/* 移动端适配 */
.login-card.mobile {
  width: 85%;
  padding: 30px 20px;
  margin: 20px;
}

@media screen and (max-width: 768px) {
  .login-header {
    margin-bottom: 30px;
  }
  
  .logo {
    font-size: 40px;
    margin-bottom: 12px;
  }
  
  .title {
    font-size: 20px;
  }
  
  :deep(.el-input__inner) {
    height: 40px;
    line-height: 40px;
    padding: 0 15px 0 35px;
    font-size: 14px;
  }
  
  :deep(.el-input__prefix) {
    left: 12px;
  }
  
  .login-button {
    padding: 10px 0;
    font-size: 15px;
  }
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .login-card {
    background-color: rgba(31, 45, 61, 0.95);
  }
  
  .title {
    color: #fff;
  }
  
  :deep(.el-input__inner) {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: #4c4d4f;
    color: #fff;
  }
  
  :deep(.el-input__inner:focus) {
    border-color: #409EFF;
  }
  
  .custom-icon {
    color: #909399;
  }
}

/* 输入框焦点效果 */
:deep(.el-input__inner:focus) {
  border-color: #409EFF;
}

/* 输入框hover效果 */
:deep(.el-input:hover .el-input__inner) {
  border-color: #c0c4cc;
}
</style> 