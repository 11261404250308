import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';
import config from '@/config';

const service = axios.create({
    baseURL: config.baseURL,
    timeout: 5000
});

// 用于防止重复提示
let isRedirecting = false;

// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = localStorage.getItem('admin_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            // 只处理401未授权的情况，其他错误交给具体组件处理
            if (error.response.status === 401 && !isRedirecting) {
                // 如果是登录接口的401错误，不处理，让组件自己处理
                if (error.config.url.includes('/api/admin/login')) {
                    return Promise.reject(error);
                }
                
                isRedirecting = true;
                
                // 清除认证信息
                localStorage.removeItem('admin_token');
                localStorage.removeItem('admin_info');
                service.defaults.headers.common['Authorization'] = '';
                
                // 如果不是在登录页，则跳转到登录页
                if (router.currentRoute.path !== '/login') {
                    Message.error('未登录或登录已过期，请重新登录');
                    router.push('/login').finally(() => {
                        setTimeout(() => {
                            isRedirecting = false;
                        }, 1000);
                    });
                } else {
                    isRedirecting = false;
                }
            }
            // 其他错误不在这里处理，交给具体组件处理
        } else if (!error.response) {
            // 网络错误等没有响应的情况
            Message.error('网络错误，请检查网络连接');
        }
        return Promise.reject(error);
    }
);

export default service; 