<template>
  <div id="app">
    <el-container v-if="isLogin">
      <!-- 移动端菜单按钮 -->
      <div 
        class="mobile-menu-btn" 
        :class="{ 'menu-visible': mobileMenuVisible }"
        @click="toggleMobileMenu" 
        v-if="isMobile"
      >
        <i :class="mobileMenuVisible ? 'el-icon-close' : 'el-icon-menu'"></i>
      </div>

      <!-- 侧边栏 -->
      <el-aside 
        :width="sidebarWidth" 
        :class="{ 
          'mobile-sidebar': isMobile,
          'show-menu': mobileMenuVisible 
        }"
      >
        <div class="sidebar-container" v-show="!isMobile || mobileMenuVisible">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-vertical"
            background-color="#304156"
            text-color="#fff"
            active-text-color="#409EFF"
            :collapse="isCollapse && !isMobile"
            router
          >
            <el-menu-item index="/dashboard">
              <i class="el-icon-s-home"></i>
              <span slot="title">仪表盘</span>
            </el-menu-item>
            
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-s-marketing"></i>
                <span>彩票管理</span>
              </template>
              <el-menu-item index="/lottery/category">彩票类别</el-menu-item>
              <el-menu-item index="/lottery/list">彩票列表</el-menu-item>
              <el-menu-item index="/lottery/draw">开奖管理</el-menu-item>
              <el-menu-item index="/lottery/results">开奖记录</el-menu-item>
              <el-menu-item index="/lottery/orders">投注记录</el-menu-item>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-user"></i>
                <span>用户管理</span>
              </template>
              <el-menu-item index="/users/list">用户列表</el-menu-item>
              <el-menu-item index="/users/recharge">充值记录</el-menu-item>
              <el-menu-item index="/users/withdraw">提现管理</el-menu-item>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title">
                <i class="el-icon-s-cooperation"></i>
                <span>代理管理</span>
              </template>
              <el-menu-item index="/agent/list">代理列表</el-menu-item>
              <el-menu-item index="/agent/commission">佣金记录</el-menu-item>
            </el-submenu>

            <el-menu-item index="/settings">
              <i class="el-icon-setting"></i>
              <span slot="title">系统设置</span>
            </el-menu-item>

            <el-menu-item index="/quick-nav">
              <i class="el-icon-guide"></i>
              <span slot="title">快捷导航</span>
            </el-menu-item>

            <el-menu-item index="/announcement">
              <i class="el-icon-bell"></i>
              <span slot="title">公告管理</span>
            </el-menu-item>

            <el-menu-item index="/friendly-link">
              <i class="el-icon-link"></i>
              <span slot="title">友情链接</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>

      <!-- 主要内容区 -->
      <el-container 
        class="main-container"
        :class="{ 'menu-visible': mobileMenuVisible }"
      >
        <el-header>
          <div class="header-left">
            <i 
              v-if="!isMobile"
              :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
              @click="toggleCollapse"
            ></i>
          </div>
          <div class="header-right">
            <el-dropdown 
              @command="handleCommand" 
              ref="adminDropdown"
              trigger="click"
              @visible-change="handleDropdownVisibleChange"
              :hide-on-click="true"
            >
              <span class="el-dropdown-link">
                管理员<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

    <!-- 登录页面 -->
    <login v-else @login-success="onLoginSuccess"></login>

    <!-- 移动端遮罩层 -->
    <div 
      class="mobile-mask" 
      :class="{ 'show': mobileMenuVisible }"
      v-if="isMobile"
      @click="closeMobileMenu"
    ></div>

    <!-- 修改密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passwordDialogVisible"
      :width="isMobile ? '90%' : '400px'"
      custom-class="password-dialog"
      @close="resetPasswordForm"
    >
      <el-form
        ref="passwordForm"
        :model="passwordForm"
        :rules="passwordRules"
        label-width="100px"
        :label-position="isMobile ? 'top' : 'right'"
        size="medium"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model="passwordForm.oldPassword"
            type="password"
            show-password
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="passwordForm.newPassword"
            type="password"
            show-password
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input
            v-model="passwordForm.confirmPassword"
            type="password"
            show-password
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button 
          @click="passwordDialogVisible = false"
          size="medium"
          :style="isMobile ? 'margin-right: 8px' : ''"
        >取 消</el-button>
        <el-button 
          type="primary" 
          @click="submitPasswordChange" 
          :loading="changing"
          size="medium"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Login from './views/Login.vue'

export default {
  name: 'App',
  components: {
    Login
  },
  data() {
    // 密码确认验证
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };

    return {
      isLogin: false,
      isCollapse: false,
      activeMenu: '/dashboard',
      isMobile: false,
      mobileMenuVisible: false,
      passwordDialogVisible: false,
      changing: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'change' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'change' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'change' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'change' },
          { validator: validateConfirmPassword, trigger: 'change' }
        ]
      },
      dropdownVisible: false
    }
  },
  computed: {
    sidebarWidth() {
      if (this.isMobile) {
        return '200px'
      }
      return this.isCollapse ? '64px' : '200px'
    }
  },
  created() {
    // 检查登录状态
    const token = localStorage.getItem('admin_token')
    if (token) {
      this.isLogin = true
      this.activeMenu = this.$route.path
    }
    // 检查设备类型
    this.checkDevice()
    // 监听窗口大小化
    window.addEventListener('resize', this.checkDevice)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    toggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
      if (this.mobileMenuVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    closeMobileMenu() {
      if (this.mobileMenuVisible) {
        this.mobileMenuVisible = false;
        document.body.style.overflow = '';
      }
    },
    checkDevice() {
      this.isMobile = window.innerWidth < 768
      if (!this.isMobile) {
        this.mobileMenuVisible = false
      }
    },
    handleDropdownVisibleChange(visible) {
      this.dropdownVisible = visible;
      if (this.isMobile) {
        document.body.style.overflow = visible ? 'hidden' : '';
      }
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      } else if (command === 'changePassword') {
        this.passwordDialogVisible = true;
      }
      this.dropdownVisible = false;
      if (this.isMobile) {
        document.body.style.overflow = '';
      }
    },
    async logout() {
      try {
        // 调用登出接口
        const res = await this.$http.post('/api/admin/logout');
        if (res.data.code === 200) {
          // 清除所有相关存储
          localStorage.removeItem('admin_token');
          localStorage.removeItem('admin_info');
          // 清除 axios 默认请求头
          this.$http.defaults.headers.common['Authorization'] = '';
          // 更新状态
          this.isLogin = false;
        }
      } catch (error) {
        console.error('Logout failed:', error);
        this.$message.error('登出失败，请重试');
      }
    },
    onLoginSuccess() {
      this.isLogin = true
      this.$router.push('/dashboard')
    },
    resetPasswordForm() {
      this.$refs.passwordForm?.resetFields();
      this.passwordForm = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      };
    },
    async submitPasswordChange() {
      try {
        await this.$refs.passwordForm.validate();
        
        // 修改密码复杂度验证规则，增加特殊字符支持
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
        if (!passwordRegex.test(this.passwordForm.newPassword)) {
          this.$message.error('新密码必须包含大小写字母、数字和特殊字符，且长度不少于8位');
          return;
        }
        
        this.changing = true;
        
        // 获取 token
        const token = localStorage.getItem('admin_token');
        if (!token) {
          this.$message.error('登录已过期，请重新登录');
          this.logout();
          return;
        }

        const res = await this.$http.post('/api/admin/change-password', {
          oldPassword: this.passwordForm.oldPassword,
          newPassword: this.passwordForm.newPassword
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (res.data.code === 200) {
          this.$message.success('密码修改成功，请重新登录');
          this.passwordDialogVisible = false;
          // 退出登录
          setTimeout(() => {
            this.logout();
          }, 1500);
        }
      } catch (error) {
        console.error('Password change failed:', error);
        if (error.response) {
          this.$message.error(error.response.data.message || '修改密码失败');
        } else {
          this.$message.error('修改密码失败，请重试');
        }
      } finally {
        this.changing = false;
      }
    }
  },
  watch: {
    $route() {
      if (this.isMobile) {
        this.closeMobileMenu();
      }
    }
  }
}
</script>

<style>
#app {
  height: 100vh;
}

.el-container {
  height: 100%;
}

.el-aside {
  background-color: #304156;
  transition: width 0.3s;
  position: relative;
  z-index: 1000;
}

.el-menu {
  border-right: none;
}

.el-header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  height: 60px !important;
  padding: 0 20px;
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  color: #333;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.el-dropdown-link {
  cursor: pointer;
  color: #333;
}

.el-main {
  background-color: #f0f2f5;
  padding: 20px;
  overflow-x: hidden;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

/* 移动端样式 */
.mobile-menu-btn {
  position: fixed;
  top: 12px;
  left: 12px;
  z-index: 2000;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 4px;
  transition: all 0.3s;
}

.mobile-menu-btn:hover {
  background: rgba(0, 0, 0, 0.05);
}

.mobile-menu-btn i {
  transition: all 0.3s;
}

.mobile-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1500;
  transform: translateX(-100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: none;
}

.mobile-sidebar.show-menu {
  transform: translateX(0);
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.1);
}

.mobile-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(2px);
}

.mobile-mask.show {
  opacity: 1;
  visibility: visible;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .el-main {
    padding: 12px;
    height: calc(100vh - 60px);
    margin-top: 60px !important;
  }

  .el-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    padding: 0 60px 0 12px !important;
  }

  .header-left {
    margin-left: 40px;
  }

  /* 显示菜单时的样式 */
  .mobile-sidebar.show-menu .sidebar-container {
    transform: translateX(0);
  }

  /* 修复菜单显示问题 */
  .mobile-sidebar {
    transform: translateX(-100%);
  }

  .mobile-sidebar.show-menu {
    transform: translateX(0);
  }

  .el-header {
    padding: 0 16px;
  }

  .header-left {
    margin-left: 32px;
  }

  .header-right {
    right: 16px;
  }

  .el-dropdown-menu {
    min-width: 120px !important;
  }

  .el-dropdown-link {
    font-size: 14px;
    padding: 0 8px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .el-icon--right {
    margin-left: 4px;
  }
}

/* 动画效果 */
.sidebar-container {
  height: 100%;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .mobile-menu-btn {
    color: #fff;
  }

  .mobile-menu-btn:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  .el-header {
    background-color: #1f2d3d;
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
  }

  .header-right {
    color: #fff;
  }

  .el-dropdown-link {
    color: #fff !important;
  }
}

/* 主容器过渡效果 */
.main-container {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 768px) {
  .el-header {
    padding: 0 60px 0 12px !important;
  }

  .header-left {
    margin-left: 0;
  }

  .mobile-sidebar {
    box-shadow: none;
  }

  .mobile-sidebar .sidebar-container {
    padding-top: 60px;
  }

  /* 菜单展开时主容器右移效果 */
  .main-container.menu-visible {
    transform: translateX(200px);
  }

  /* 优化菜单按钮位置 */
  .mobile-menu-btn {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mobile-menu-btn.menu-visible {
    transform: translateX(200px);
  }

  /* 优化卡片在移动端的显示 */
  .el-card {
    margin-bottom: 12px;
  }

  .main-container {
    padding-top: 0 !important;
  }

  .el-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }

  .mobile-menu-btn {
    left: 16px;
    top: 14px;
  }

  .el-main {
    margin-top: -60px;
    padding-top: 72px;
  }
}

/* 优化移动端下拉菜单样式 */
@media screen and (max-width: 768px) {
  .el-dropdown-menu {
    margin-top: 0 !important;
  }

  .el-dropdown-menu__item {
    line-height: 40px !important;
    padding: 0 20px !important;
  }

  /* 确保下拉菜单在移动端时完全关闭 */
  .el-dropdown-menu--hidden {
    display: none !important;
  }
}

/* 密码修改弹框式 */
.password-dialog {
  border-radius: 8px;
}

.password-dialog .el-dialog__header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.password-dialog .el-dialog__title {
  font-size: 18px;
  font-weight: 500;
  color: #303133;
}

.password-dialog .el-dialog__body {
  padding: 24px 24px 0;
}

.password-dialog .el-dialog__footer {
  padding: 16px 24px 24px;
  text-align: right;
}

.password-dialog .el-form-item {
  margin-bottom: 24px;
}

.password-dialog .el-input__inner {
  height: 40px;
  line-height: 40px;
}

.password-dialog .el-form-item__label {
  font-weight: normal;
  color: #606266;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .password-dialog {
    margin-top: 20vh !important;
  }

  .password-dialog .el-dialog__header {
    padding: 16px;
  }

  .password-dialog .el-dialog__title {
    font-size: 16px;
  }

  .password-dialog .el-dialog__body {
    padding: 16px 16px 0;
  }

  .password-dialog .el-dialog__footer {
    padding: 12px 16px 16px;
  }

  .password-dialog .el-form-item {
    margin-bottom: 20px;
  }

  .password-dialog .el-form-item__label {
    padding-bottom: 8px;
    line-height: 1.2;
  }

  .password-dialog .el-form-item__content {
    line-height: 1;
  }

  .password-dialog .dialog-footer {
    display: flex;
    justify-content: flex-end;
  }

  .password-dialog .dialog-footer .el-button {
    flex: 1;
    margin-left: 8px;
  }

  .password-dialog .dialog-footer .el-button:first-child {
    margin-left: 0;
  }
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .password-dialog {
    background-color: #1f2d3d;
  }

  .password-dialog .el-dialog__title {
    color: #fff;
  }

  .password-dialog .el-dialog__header {
    border-bottom-color: #304156;
  }

  .password-dialog .el-form-item__label {
    color: #909399;
  }

  .password-dialog .el-input__inner {
    background-color: #304156;
    border-color: #304156;
    color: #fff;
  }

  .password-dialog .el-input__inner:focus {
    border-color: #409EFF;
  }
}

/* 优化移动端卡片间距 */
@media screen and (max-width: 768px) {
  .el-card {
    margin-bottom: 12px;
  }

  .el-row {
    margin-top: 0 !important;
  }

  /* 调整图表容器的上边距 */
  .chart-row {
    margin-top: 12px !important;
  }
}

/* 调整内容区域的上边距 */
.main-container {
  padding-top: 0 !important;
}

/* 调整所有页面内容的上边距 */
.dashboard,
.user-list,
.vip-management,
.recharge-record,
.withdraw-management,
.balance-record,
.lottery-list,
.lottery-orders,
.lottery-results,
.agent-list,
.commission-record,
.settings {
  padding-top: 12px !important;
  min-height: calc(100vh - 72px) !important;
  height: 100%;
}
</style> 