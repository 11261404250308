import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import QuickNav from '../views/QuickNav.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/users/list',
    name: 'UserList',
    component: () => import('../views/users/UserList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users/recharge',
    name: 'RechargeRecord',
    component: () => import('../views/users/RechargeRecord.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users/withdraw',
    name: 'WithdrawManagement',
    component: () => import('../views/users/WithdrawManagement.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/lottery/list',
    name: 'LotteryList',
    component: () => import('../views/lottery/LotteryList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/lottery/orders',
    name: 'LotteryOrders',
    component: () => import('../views/lottery/LotteryOrders.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/lottery/results',
    name: 'LotteryResults',
    component: () => import('../views/lottery/LotteryResults.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/agent/list',
    name: 'AgentList',
    component: () => import('../views/agent/AgentList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/agent/commission',
    name: 'CommissionRecord',
    component: () => import('../views/agent/CommissionRecord.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: () => import('../views/announcement/AnnouncementList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/friendly-link',
    name: 'FriendlyLink',
    component: () => import('../views/friendlyLink/FriendlyLinkList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/lottery/category',
    name: 'LotteryCategory',
    component: () => import('../views/lottery/LotteryCategory.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/lottery/draw',
    name: 'LotteryDraw',
    component: () => import('../views/lottery/LotteryDraw.vue'),
    meta: { 
      title: '开奖管理',
      requiresAuth: true 
    }
  },
  {
    path: '/lottery/:id/plays',
    name: 'LotteryPlaysConfig',
    component: () => import('../views/lottery/LotteryPlaysConfig.vue'),
    props: true,
    meta: { 
      title: '彩票玩法配置',
      requiresAuth: true 
    }
  },
  {
    path: '/lottery/:id/api-config',
    name: 'LotteryApiConfig',
    component: () => import('../views/lottery/LotteryApiConfig.vue'),
    props: true,
    meta: { 
      title: '开奖API配置',
      requiresAuth: true 
    }
  },
  {
    path: '/quick-nav',
    name: 'QuickNav',
    component: QuickNav,
    meta: {
      title: '快捷导航设置',
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('admin_token');
    
    if (to.path === '/login') {
        if (token) {
            next('/dashboard');
        } else {
            next();
        }
        return;
    }
    
    if (!token) {
        next('/login');
        return;
    }
    
    next();
});

// 处理路由重复导航错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

export default router 