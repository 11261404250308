<template>
  <div class="quick-nav">
    <el-card>
      <div class="toolbar">
        <div class="title">快捷导航设置</div>
      </div>

      <!-- PC端表格 -->
      <el-table
        v-if="!isMobile"
        :data="navList"
        style="width: 100%; margin-top: 20px;"
        v-loading="loading"
      >
        <el-table-column label="序号" width="60" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="图标" width="80">
          <template slot-scope="scope">
            <div class="icon-cell" @click="handleSelectIcon(scope.$index)">
              <el-image
                v-if="scope.row.icon"
                style="width: 32px; height: 32px"
                :src="scope.row.icon"
                fit="cover"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-button v-else size="mini" type="text">
                <i class="el-icon-plus"></i>选择
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.title"
              placeholder="请输入标题"
              maxlength="10"
              show-word-limit
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="link" label="链接">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.link"
              placeholder="请输入链接地址"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端列表 -->
      <div v-else class="mobile-list">
        <div
          v-for="(item, index) in navList"
          :key="index"
          class="mobile-item"
        >
          <div class="mobile-item-header">
            <div class="index">{{ index + 1 }}</div>
            <div class="icon-wrapper" @click="handleSelectIcon(index)">
              <el-image
                v-if="item.icon"
                style="width: 40px; height: 40px"
                :src="item.icon"
                fit="cover"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div v-else class="icon-placeholder">
                <i class="el-icon-plus"></i>
                <span>选择图标</span>
              </div>
            </div>
          </div>
          <div class="mobile-item-content">
            <el-input
              v-model="item.title"
              placeholder="请输入标题"
              maxlength="10"
              show-word-limit
              class="mb-2"
            ></el-input>
            <el-input
              v-model="item.link"
              placeholder="请输入链接地址"
            ></el-input>
          </div>
        </div>
      </div>

      <div class="actions">
        <el-button type="primary" @click="handleSave" :loading="saving">
          保存设置
        </el-button>
      </div>
    </el-card>

    <!-- 图标选择对话框 -->
    <el-dialog
      title="选择图标"
      :visible.sync="iconDialogVisible"
      :width="isMobile ? '95%' : '600px'"
      :fullscreen="isMobile"
      custom-class="icon-dialog"
    >
      <div class="icon-list">
        <div
          v-for="icon in iconList"
          :key="icon.id"
          class="icon-item"
          :class="{ active: currentIcon === icon.url }"
          @click="handleIconSelect(icon)"
        >
          <img :src="icon.url" :alt="icon.name">
          <span>{{ icon.name }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.quick-nav {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .icon-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;

    &:hover {
      opacity: 0.8;
    }
  }

  .actions {
    margin-top: 20px;
    text-align: center;
  }

  .mobile-list {
    .mobile-item {
      background: #fff;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 12px;
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);

      &:last-child {
        margin-bottom: 0;
      }

      .mobile-item-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .index {
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background-color: #f0f2f5;
          border-radius: 12px;
          margin-right: 12px;
          font-size: 13px;
          color: #606266;
        }

        .icon-wrapper {
          flex: 1;
          display: flex;
          justify-content: center;
          cursor: pointer;

          .icon-placeholder {
            width: 40px;
            height: 40px;
            border: 1px dashed #dcdfe6;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #909399;

            i {
              font-size: 16px;
              margin-bottom: 2px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }

      .mobile-item-content {
        .mb-2 {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.icon-dialog {
  .icon-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 12px;
    padding: 0 4px;
    max-height: 60vh;
    overflow-y: auto;

    .icon-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 8px;
      border: 1px solid #eee;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        border-color: #409EFF;
        background-color: #f5f7fa;
      }

      &.active {
        border-color: #409EFF;
        background-color: #ecf5ff;
      }

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }

      span {
        font-size: 12px;
        color: #666;
        text-align: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .icon-list {
      grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
      gap: 8px;

      .icon-item {
        padding: 6px;

        img {
          width: 28px;
          height: 28px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .quick-nav {
    .mobile-list {
      .mobile-item {
        background: #1e1e1e;

        .mobile-item-header {
          .index {
            background-color: #363636;
            color: #909399;
          }

          .icon-wrapper {
            .icon-placeholder {
              border-color: #4c4c4c;
              color: #909399;
            }
          }
        }
      }
    }
  }

  .icon-dialog {
    .icon-list {
      .icon-item {
        border-color: #4c4c4c;
        background-color: #1e1e1e;

        &:hover {
          background-color: #363636;
        }

        &.active {
          border-color: #409EFF;
          background-color: #363636;
        }

        span {
          color: #909399;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'QuickNav',
  data() {
    return {
      loading: false,
      saving: false,
      navList: Array(4).fill().map(() => ({
        title: '',
        icon: '',
        link: ''
      })),
      iconDialogVisible: false,
      iconList: [],
      currentIcon: '',
      currentEditIndex: -1,
      isMobile: false
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.loadLocalIcons()
    this.fetchData()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchData() {
      this.loading = true
      try {
        const res = await this.$http.get('/api/admin/quick-nav')
        if (res.data.code === 200) {
          const data = res.data.data || []
          this.navList = Array(4).fill().map((_, index) => ({
            title: data[index]?.title || '',
            icon: data[index]?.icon || '',
            link: data[index]?.link || ''
          }))
        }
      } catch (error) {
        console.error('获取快捷导航设置失败:', error)
        this.$message.error('获取设置失败')
      } finally {
        this.loading = false
      }
    },
    loadLocalIcons() {
      try {
        const iconContext = require.context('../../public/static/icon', false, /\.(png|jpe?g|gif|svg)$/i)
        this.iconList = iconContext.keys().map((key, index) => {
          const name = key.replace(/^\.\//, '').replace(/\.[^/.]+$/, '')
          const url = `/static/icon/${key.replace(/^\.\//, '')}`
          return {
            id: `icon_${index}`,
            name,
            url
          }
        })
      } catch (error) {
        console.error('加载图标失败:', error)
        this.$message.error('加载图标失败')
      }
    },
    handleSelectIcon(index) {
      this.currentEditIndex = index
      this.currentIcon = this.navList[index].icon
      this.iconDialogVisible = true
    },
    handleIconSelect(icon) {
      if (this.currentEditIndex > -1) {
        this.navList[this.currentEditIndex].icon = icon.url
      }
      this.iconDialogVisible = false
      this.currentEditIndex = -1
    },
    async handleSave() {
      const validData = this.navList.filter(item => 
        item.title.trim() && item.icon && item.link.trim()
      )

      if (validData.length === 0) {
        this.$message.warning('请至少配置一个快捷导航')
        return
      }

      this.saving = true
      try {
        const res = await this.$http.post('/api/admin/quick-nav', {
          navList: this.navList
        })
        if (res.data.code === 200) {
          this.$message.success('保存成功')
        }
      } catch (error) {
        console.error('保存快捷导航设置失败:', error)
        this.$message.error('保存失败')
      } finally {
        this.saving = false
      }
    }
  }
}
</script> 