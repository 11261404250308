<template>
  <div class="dashboard">
    <!-- 数据概览卡片 -->
    <el-row :gutter="24">
      <el-col :xs="24" :sm="12" :md="6">
        <el-card shadow="hover" class="data-card">
          <div class="data-header">
            <div class="title">总用户数</div>
            <i class="el-icon-user icon"></i>
          </div>
          <div class="data-content">
            <div class="number">{{ statistics.userCount || 0 }}</div>
            <div class="trend">
              今日新增: <span class="up">+{{ statistics.newUsers || 0 }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
      
      <el-col :xs="24" :sm="12" :md="6">
        <el-card shadow="hover" class="data-card">
          <div class="data-header">
            <div class="title">今日投注</div>
            <i class="el-icon-money icon"></i>
          </div>
          <div class="data-content">
            <div class="number">{{ statistics.todayBets || 0 }}</div>
            <div class="trend">
              金额: <span class="amount">¥{{ statistics.todayBetAmount || 0 }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
      
      <el-col :xs="24" :sm="12" :md="6">
        <el-card shadow="hover" class="data-card">
          <div class="data-header">
            <div class="title">今日充值</div>
            <i class="el-icon-wallet icon"></i>
          </div>
          <div class="data-content">
            <div class="number">¥{{ statistics.todayRecharge || 0 }}</div>
            <div class="trend">
              笔数: <span class="count">{{ statistics.rechargeCount || 0 }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
      
      <el-col :xs="24" :sm="12" :md="6">
        <el-card shadow="hover" class="data-card">
          <div class="data-header">
            <div class="title">今日提现</div>
            <i class="el-icon-bank-card icon"></i>
          </div>
          <div class="data-content">
            <div class="number">¥{{ statistics.todayWithdraw || 0 }}</div>
            <div class="trend">
              笔数: <span class="count">{{ statistics.withdrawCount || 0 }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 图表区域 -->
    <el-row :gutter="24" class="chart-row">
      <el-col :xs="24" :lg="12">
        <el-card shadow="hover" class="chart-card">
          <div slot="header" class="chart-header">
            <span>最近7天投注趋势</span>
            <el-radio-group v-model="betChartType" size="small">
              <el-radio-button label="amount">金额</el-radio-button>
              <el-radio-button label="count">笔数</el-radio-button>
            </el-radio-group>
          </div>
          <div class="chart-container" ref="betChart"></div>
        </el-card>
      </el-col>
      
      <el-col :xs="24" :lg="12">
        <el-card shadow="hover" class="chart-card">
          <div slot="header" class="chart-header">
            <span>充值/提现对比</span>
            <el-select v-model="financeTimeRange" size="small" style="width: 120px">
              <el-option label="最近7天" value="7"></el-option>
              <el-option label="最近30天" value="30"></el-option>
            </el-select>
          </div>
          <div class="chart-container" ref="financeChart"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'Dashboard',
  data() {
    return {
      statistics: {
        userCount: 0,
        newUsers: 0,
        todayBets: 0,
        todayBetAmount: 0,
        todayRecharge: 0,
        rechargeCount: 0,
        todayWithdraw: 0,
        withdrawCount: 0
      },
      betChartType: 'amount',
      financeTimeRange: '7',
      betChart: null,
      financeChart: null
    }
  },
  watch: {
    betChartType() {
      this.initBetChart()
    },
    financeTimeRange() {
      this.initFinanceChart()
    }
  },
  mounted() {
    this.fetchDashboardData()
    this.initCharts()
    
    // 监听窗口大小变化，重绘图表
    window.addEventListener('resize', this.resizeCharts)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCharts)
    this.disposeCharts()
  },
  methods: {
    async fetchDashboardData() {
      try {
        const res = await this.$http.get('/api/admin/dashboard')
        if (res.data.code === 200) {
          this.statistics = res.data.data.statistics
          this.initCharts()
        }
      } catch (error) {
        console.error('获取仪表盘数据失败:', error)
      }
    },
    initCharts() {
      this.initBetChart()
      this.initFinanceChart()
    },
    initBetChart() {
      if (!this.betChart) {
        this.betChart = echarts.init(this.$refs.betChart)
      }
      
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['11-01', '11-02', '11-03', '11-04', '11-05', '11-06', '11-07']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: this.betChartType === 'amount' ? '投注金额' : '投注笔数',
            type: 'bar',
            data: this.betChartType === 'amount' 
              ? [1000, 1500, 2000, 1800, 2500, 3000, 2800]
              : [100, 150, 200, 180, 250, 300, 280],
            itemStyle: {
              color: '#409EFF'
            }
          }
        ]
      }
      
      this.betChart.setOption(option)
    },
    initFinanceChart() {
      if (!this.financeChart) {
        this.financeChart = echarts.init(this.$refs.financeChart)
      }
      
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['充值', '提现']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['11-01', '11-02', '11-03', '11-04', '11-05', '11-06', '11-07']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '充值',
            type: 'line',
            data: [5000, 6000, 7000, 6500, 8000, 9000, 8500],
            itemStyle: {
              color: '#67C23A'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(103,194,58,0.3)' },
                { offset: 1, color: 'rgba(103,194,58,0.1)' }
              ])
            }
          },
          {
            name: '提现',
            type: 'line',
            data: [3000, 4000, 5000, 4500, 6000, 7000, 6500],
            itemStyle: {
              color: '#F56C6C'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(245,108,108,0.3)' },
                { offset: 1, color: 'rgba(245,108,108,0.1)' }
              ])
            }
          }
        ]
      }
      
      this.financeChart.setOption(option)
    },
    resizeCharts() {
      this.betChart && this.betChart.resize()
      this.financeChart && this.financeChart.resize()
    },
    disposeCharts() {
      this.betChart && this.betChart.dispose()
      this.financeChart && this.financeChart.dispose()
    }
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
  padding-top: 80px;
  background-color: #f0f2f5;
  min-height: 100%;
}

.el-row {
  margin: 0 -12px;
}

.el-col {
  padding: 12px;
}

.data-card {
  height: 120px;
  background: #fff;
  border-radius: 4px;
  transition: all 0.3s;
  padding: 16px;
}

.data-card:hover {
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}

.data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.data-header .title {
  font-size: 16px;
  color: #606266;
}

.data-header .icon {
  font-size: 28px;
  color: #409EFF;
}

.data-content .number {
  font-size: 24px;
  font-weight: bold;
  color: #303133;
  margin-bottom: 8px;
}

.data-content .trend {
  font-size: 14px;
  color: #909399;
}

.data-content .trend .up {
  color: #67C23A;
}

.data-content .trend .amount {
  color: #E6A23C;
}

.data-content .trend .count {
  color: #409EFF;
}

.chart-row {
  margin-top: 0;
}

.chart-card {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 12px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.chart-container {
  height: 350px;
  padding: 16px;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .dashboard {
    padding: 16px;
    padding-top: 70px;
  }
  
  .el-row {
    margin: 0 -8px;
  }
  
  .el-col {
    padding: 8px;
  }
  
  .data-card {
    height: auto;
    min-height: 100px;
    padding: 12px;
  }
  
  .data-header {
    margin-bottom: 12px;
  }
  
  .data-header .title {
    font-size: 14px;
  }
  
  .data-header .icon {
    font-size: 24px;
  }
  
  .data-content .number {
    font-size: 20px;
  }
  
  .data-content .trend {
    font-size: 12px;
  }
  
  .chart-container {
    margin-top: 12px;
    height: 280px;
    padding: 12px;
  }
  
  .chart-header {
    padding: 12px;
  }
}

/* 处理图表在小屏幕上的显示 */
@media screen and (max-width: 576px) {
  .chart-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .chart-container {
    height: 200px;
  }
}
</style> 